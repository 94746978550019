<template>
	<div id="top">
		<nav class="main-nav fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link
							class="logo"
							:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						/>

						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
							<lang-select v-if="page" :items="langmenu" />
						</div>
						<div class="buttons">
							<div class="menu-button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								<span>{{ $t('buttonMenu') }}</span>
							</div>
							<book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ defaults[locale].website.book }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const showMobileNav = ref(false);
const showLanguageMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
	.nav-content li:hover :deep(.submenu ul) {
		z-index: 1;
		margin-top: 0;
		opacity: 1;
		max-height: 500px;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: all 0.3s ease-in-out;

	&.fixed {
		background: var(--dark-background-color);
		box-shadow: 0 0 20px rgba(0 0 0 / 10%);
	}

	ul {
		display: inline-block;
		list-style: none;

		li {
			display: inline-block;
		}
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 8px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 11px 30px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
		}
	}
}

.logo {
	padding: 0;
	text-decoration: none;
	margin: 5px 0 15px;
	width: 158px;
	height: 64px;
	background: url('~/assets/images/logo-stadsvilla-mout-full-white.svg') no-repeat center center;
	background-size: 100%;
	transition: all 0.3s ease-in-out;

	h3 {
		margin: 0 0 5px;
		font-size: 24px;
	}

	img {
		max-width: 150px;
	}
}

.fixed {
	.logo {
		background: url('~/assets/images/logo-stadsvilla-mout-icon-only-white.svg') no-repeat center left;
		background-size: 62px;
		height: 54px;
		margin: 10px 0;
	}
}

.nav-content {
	margin: auto;

	a {
		color: #fff;
		text-decoration: none;
		margin: 0 10px;
		letter-spacing: 1px;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 16px;
		padding: 0 1px 5px;
		position: relative;

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 1px;
			height: 1px;
			background: rgba(255 255 255 / 0%);
			transition: all 0.3s ease-in-out;
			margin: 0 auto;
		}

		&:hover,
		&.router-link-active {
			&::after {
				background: rgba(255 255 255 / 100%);
				width: 100%;
			}
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 38px;
		cursor: pointer;
		position: absolute;
		top: 20px;
		width: auto;
		right: 25px;
		padding: 0;
		margin: 0;
	}
}

.lang-nav.mobile-only {
	.language {
		margin: 20px 0;
		color: #fff;
	}

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: #fff;
		text-decoration: none;
		margin: 4px 0;
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		span {
			margin: 6px 0 0 3px;
		}

		&.router-link-active,
		&:hover {
			border: none;

			&::after {
				display: none;
			}

			span {
				border-bottom: 1px solid #fff;
			}
		}
	}
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: var(--body-color);
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 13px;
	text-align: center;
	background: var(--primary-background-color);
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	.menu-button {
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 10px 0 0;
		cursor: pointer;
		color: #fff;

		svg {
			font-size: 22px;
		}

		span {
			margin: 0 0 0 5px;
		}
	}

	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
			margin: 30px 0 0;
			padding: 0 10px;
		}
	}

	.lang-nav {
		.language {
			.flag::before {
				top: 3px;
			}
		}
	}

	.language-navigation {
		display: none;
	}

	.nav-bar .buttons .menu-button {
		.icon {
			display: unset;
		}
	}

	.main-nav {
		position: fixed !important;
		top: 0;

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				text-align: center;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 20px;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: rgba(123 142 161 / 90%);
			backdrop-filter: blur(14px);
			text-align: left;
			padding: 20px;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}

	.logo {
		h3 {
			font-size: 22px;
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons {
		.button {
			padding: 12px 20px 12px 15px;
		}

		.menu-button {
			margin-right: 5px;

			.icon {
				font-size: 30px;
				margin: 2px 0 0;
			}

			span {
				display: none;
			}
		}

		.icon {
			font-size: 16px;
			margin: 0 8px 0 0;
			display: unset;
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons {
		.button {
			padding: 16px;
		}

		span {
			display: none;
		}

		.icon {
			margin-right: 0;
			font-size: 16px;
		}
	}

	.nav-bar .logo {
		font-size: 22px;
	}
}
</style>
